@font-face {
	font-family: "CeraPRO";
	font-style: normal;
	font-weight: 100 200;
	src: url("../resources/fonts/CeraPRO-Thin.otf") format("opentype");
}

@font-face {
	font-family: "CeraPRO";
	font-style: normal;
	font-weight: 300;
	src: url("../resources/fonts/CeraPRO-Light.otf") format("opentype");
}

@font-face {
	font-family: "CeraPRO";
	font-style: normal;
	font-weight: 400;
	src: url("../resources/fonts/CeraPRO-Regular.otf") format("opentype");
}

@font-face {
	font-family: "CeraPRO";
	font-style: normal;
	font-weight: 500;
	src: url("../resources/fonts/CeraPRO-Medium.otf") format("opentype");
}

@font-face {
	font-family: "CeraPRO";
	font-style: normal;
	font-weight: 600 700;
	src: url("../resources/fonts/CeraPRO-Bold.otf") format("opentype");
}

@font-face {
	font-family: "CeraPRO";
	font-style: normal;
	font-weight: 800 900;
	src: url("../resources/fonts/CeraPRO-Black.otf") format("opentype");
}
