.dayGroup {
  display: flex;
}

.leftCol {
  width: 40px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(2px);
}

.timePoint {
  height: 20px;
  width: 20px;
  border: 5px solid rgb(99, 134, 163);
  border-radius: 50%;
  flex-shrink: 0;
}

.timeLine {
  width: 2px;
  flex-grow: 1;
  background-color: rgb(99, 134, 163);
}

.rightCol {
  flex-grow: 1;
}

.dateHeader {
  display: inline-block;
  color: white;
  background-color: rgb(244, 182, 201);
  padding: 2px 10px;
  border-radius: 3px;
}

.levelSummary {
  text-decoration: underline;
}

.expandableFrame {
  position: relative;
  overflow: hidden;
  transition: height 0.2s;
}

.expandableContent {
  position: absolute;
  transition: opacity 0.2s;
}
